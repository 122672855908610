<template>
  <div class="view-home">
    <div class="faq-block">
      <div class="container">
        <nav class="breadcrumbs-block">
          <ul class="breadcrumbs-list">
            <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
            <li class="breadcrumbs-item">FAQ</li>
          </ul>
        </nav>
        <div class="title">
          FAQ
        </div>
      </div>
    </div>
    <div class="container">
      <div class="faq-list">
        <div id="accordion">
          <div class="card" v-for="(data, key) in accordionData">
            <div class="card-header" @click="accordion = 'collapse' + key" :id="'heading' + key">
              <h5 class="mb-0">
                <button class="btn btn-link" :class="{'active': accordion == 'collapse' + key}" data-toggle="collapse" :data-target="'#collapse' + key" aria-expanded="false" :aria-controls="'collapse' + key">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="plus-sign">
                    <path d="M11.883 3.3405L12 3.3335C12.2449 3.33353 12.4813 3.42345 12.6644 3.58621C12.8474 3.74897 12.9643 3.97324 12.993 4.2165L13 4.3335V11.3335H20C20.2449 11.3335 20.4813 11.4235 20.6644 11.5862C20.8474 11.749 20.9643 11.9732 20.993 12.2165L21 12.3335C21 12.5784 20.91 12.8148 20.7473 12.9979C20.5845 13.1809 20.3603 13.2978 20.117 13.3265L20 13.3335H13V20.3335C13 20.5784 12.91 20.8148 12.7473 20.9979C12.5845 21.1809 12.3603 21.2978 12.117 21.3265L12 21.3335C11.7551 21.3335 11.5187 21.2435 11.3356 21.0808C11.1526 20.918 11.0357 20.6937 11.007 20.4505L11 20.3335V13.3335H4C3.75507 13.3335 3.51866 13.2435 3.33563 13.0808C3.15259 12.918 3.03566 12.6937 3.007 12.4505L3 12.3335C3.00003 12.0886 3.08996 11.8522 3.25272 11.6691C3.41547 11.4861 3.63975 11.3692 3.883 11.3405L4 11.3335H11V4.3335C11 4.08856 11.09 3.85216 11.2527 3.66912C11.4155 3.48609 11.6397 3.36915 11.883 3.3405L12 3.3335L11.883 3.3405Z" fill="#1090CB"/>
                  </svg>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="minus-sign">
                    <path d="M3.99609 13.5H20.0001C20.2653 13.5 20.5197 13.3946 20.7072 13.2071C20.8947 13.0196 21.0001 12.7652 21.0001 12.5C21.0001 12.2348 20.8947 11.9804 20.7072 11.7929C20.5197 11.6054 20.2653 11.5 20.0001 11.5H3.99609C3.73088 11.5 3.47652 11.6054 3.28899 11.7929C3.10145 11.9804 2.99609 12.2348 2.99609 12.5C2.99609 12.7652 3.10145 13.0196 3.28899 13.2071C3.47652 13.3946 3.73088 13.5 3.99609 13.5Z" fill="#1090CB"/>
                  </svg>
<!--                  {{ data.title }}-->
                  <span v-html="$t(data.title)"></span>
                </button>
              </h5>
            </div>

            <div :id="'collapse' + key" :class="{'show': accordion == 'collapse' + key}" class="collapse" :aria-labelledby="'heading' + key" data-parent="#accordion">
              <div class="card-body pt-0">
                <div v-html="$t(data.content + data.content2)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="support-block">
      <div class="container">
        <div class="support-wrapper">
          <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="39.5002" cy="38.8335" r="38.6667" fill="#1090CB"/>
            <g clip-path="url(#clip0_2366_12155)">
              <path
                  d="M40.0304 28.4277C32.3829 28.4277 26.1611 34.6495 26.1611 42.2971C26.1611 49.9447 32.3829 56.1664 40.0304 56.1664C47.678 56.1664 53.8997 49.9446 53.8997 42.2971C53.8997 34.6496 47.678 28.4277 40.0304 28.4277ZM40.0304 54.1352C33.5029 54.1352 28.1924 48.8246 28.1924 42.2971C28.1924 35.7696 33.5029 30.4591 40.0304 30.4591C46.5579 30.4591 51.8685 35.7696 51.8685 42.2971C51.8685 48.8246 46.558 54.1352 40.0304 54.1352Z"
                  fill="white"/>
              <path
                  d="M46.0872 44.8763C45.6615 44.5111 45.0203 44.5601 44.6551 44.9859C43.4954 46.3379 41.8098 47.1134 40.0303 47.1134C38.251 47.1134 36.5654 46.338 35.4056 44.986C35.0404 44.5603 34.3993 44.5112 33.9735 44.8764C33.5478 45.2416 33.4987 45.8828 33.8639 46.3085C35.41 48.1109 37.6576 49.1447 40.0304 49.1447C42.4032 49.1447 44.6509 48.1109 46.1969 46.3083C46.5621 45.8827 46.513 45.2415 46.0872 44.8763Z"
                  fill="white"/>
              <path
                  d="M34.3926 40.6996C34.9535 40.6996 35.4082 40.2448 35.4082 39.6839C35.4082 39.3583 35.6731 39.0934 35.9988 39.0934C36.3244 39.0934 36.5893 39.3583 36.5893 39.6839C36.5893 40.2448 37.044 40.6996 37.6049 40.6996C38.1658 40.6996 38.6206 40.2448 38.6206 39.6839C38.6206 38.2383 37.4444 37.0621 35.9988 37.0621C34.5531 37.0621 33.377 38.2383 33.377 39.6839C33.377 40.2449 33.8317 40.6996 34.3926 40.6996Z"
                  fill="white"/>
              <path
                  d="M44.0627 37.0621C42.6171 37.0621 41.4409 38.2383 41.4409 39.6839C41.4409 40.2448 41.8956 40.6996 42.4565 40.6996C43.0174 40.6996 43.4722 40.2448 43.4722 39.6839C43.4722 39.3583 43.7371 39.0934 44.0627 39.0934C44.3883 39.0934 44.6533 39.3583 44.6533 39.6839C44.6533 40.2448 45.108 40.6996 45.6689 40.6996C46.2298 40.6996 46.6845 40.2448 46.6845 39.6839C46.6845 38.2382 45.5084 37.0621 44.0627 37.0621Z"
                  fill="white"/>
              <path
                  d="M48.3979 27.2049H49.8796C50.4405 27.2049 50.8952 26.7501 50.8952 26.1892C50.8952 25.6283 50.4405 25.1736 49.8796 25.1736H48.3979C47.8371 25.1736 47.3823 25.6283 47.3823 26.1892C47.3823 26.7501 47.8371 27.2049 48.3979 27.2049Z"
                  fill="white"/>
              <path
                  d="M52.1079 30.879C52.6688 30.879 53.1235 30.4242 53.1235 29.8633V28.3817C53.1235 27.8208 52.6688 27.3661 52.1079 27.3661C51.547 27.3661 51.0923 27.8208 51.0923 28.3817V29.8633C51.0923 30.4243 51.547 30.879 52.1079 30.879Z"
                  fill="white"/>
              <path
                  d="M55.8176 25.1736H54.3359C53.775 25.1736 53.3203 25.6283 53.3203 26.1892C53.3203 26.7501 53.775 27.2049 54.3359 27.2049H55.8176C56.3785 27.2049 56.8332 26.7501 56.8332 26.1892C56.8332 25.6283 56.3785 25.1736 55.8176 25.1736Z"
                  fill="white"/>
              <path
                  d="M52.1079 25.0127C52.6688 25.0127 53.1235 24.558 53.1235 23.9971V22.5154C53.1235 21.9545 52.6688 21.4998 52.1079 21.4998C51.547 21.4998 51.0923 21.9545 51.0923 22.5154V23.9971C51.0923 24.558 51.547 25.0127 52.1079 25.0127Z"
                  fill="white"/>
              <path
                  d="M24.365 26.5282H23.1821C22.6212 26.5282 22.1665 26.9829 22.1665 27.5438C22.1665 28.1047 22.6212 28.5594 23.1821 28.5594H24.365C24.9259 28.5594 25.3806 28.1047 25.3806 27.5438C25.3806 26.9829 24.9259 26.5282 24.365 26.5282Z"
                  fill="white"/>
              <path
                  d="M27.498 30.8145V29.6317C27.498 29.0708 27.0433 28.6161 26.4824 28.6161C25.9215 28.6161 25.4668 29.0708 25.4668 29.6317V30.8145C25.4668 31.3754 25.9215 31.8302 26.4824 31.8302C27.0433 31.8302 27.498 31.3754 27.498 30.8145Z"
                  fill="white"/>
              <path
                  d="M28.5996 28.5594H29.7825C30.3434 28.5594 30.7981 28.1047 30.7981 27.5438C30.7981 26.9829 30.3434 26.5282 29.7825 26.5282H28.5996C28.0387 26.5282 27.584 26.9829 27.584 27.5438C27.584 28.1047 28.0387 28.5594 28.5996 28.5594Z"
                  fill="white"/>
              <path
                  d="M26.4824 26.4707C27.0433 26.4707 27.498 26.016 27.498 25.4551V24.2722C27.498 23.7113 27.0433 23.2566 26.4824 23.2566C25.9215 23.2566 25.4668 23.7113 25.4668 24.2722V25.4551C25.4668 26.016 25.9215 26.4707 26.4824 26.4707Z"
                  fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_2366_12155">
                <rect width="34.6667" height="34.6667" fill="white" transform="translate(22.1665 21.4998)"/>
              </clipPath>
            </defs>
          </svg>
          <div class="support-title">
            {{ $t('canNotFindAnswer') }}
          </div>
          <div class="support-subtitle">
            {{ $t('writeYourQuestionToSupport') }}
          </div>
          <div class="support-contacts">
            <a href="mailto:qabilet@el-umiti.kz" class="support-mail">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 21.0002H7.5C4.5 21.0002 2.5 19.5002 2.5 16.0002V9.00018C2.5 5.50018 4.5 4.00018 7.5 4.00018H17.5C20.5 4.00018 22.5 5.50018 22.5 9.00018V16.0002C22.5 19.5002 20.5 21.0002 17.5 21.0002Z" stroke="#1090CB" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.5 9.50018L14.37 12.0002C13.34 12.8202 11.65 12.8202 10.62 12.0002L7.5 9.50018" stroke="#1090CB" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              qabilet@el-umiti.kz
            </a>
            <a href="tel:87273100258" class="support-phone">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 23.0002C18.299 23.0002 23 18.2992 23 12.5002C23 6.70119 18.299 2.00018 12.5 2.00018C6.70101 2.00018 2 6.70119 2 12.5002C2 14.3833 2.49575 16.1507 3.36386 17.6789L2 23.0002L7.48615 21.728C8.97603 22.5393 10.6842 23.0002 12.5 23.0002ZM12.5 21.3848C17.4068 21.3848 21.3846 17.407 21.3846 12.5002C21.3846 7.59335 17.4068 3.61557 12.5 3.61557C7.59316 3.61557 3.61538 7.59335 3.61538 12.5002C3.61538 14.3947 4.20838 16.1508 5.21888 17.5928L4.42308 20.5771L7.45995 19.8179C8.89202 20.8061 10.6284 21.3848 12.5 21.3848Z" fill="#1090CB" stroke="#1090CB" stroke-width="0.2"/>
                <path d="M9.87502 7.62516C9.62537 7.12373 9.2424 7.16812 8.85551 7.16812C8.16407 7.16812 7.08594 7.99633 7.08594 9.53771C7.08594 10.801 7.64259 12.1838 9.51831 14.2523C11.3285 16.2487 13.707 17.2814 15.6817 17.2462C17.6563 17.211 18.0625 15.5118 18.0625 14.938C18.0625 14.6836 17.9047 14.5567 17.796 14.5223C17.1231 14.1993 15.882 13.5976 15.5996 13.4845C15.3172 13.3715 15.1698 13.5244 15.0781 13.6076C14.8221 13.8516 14.3144 14.5708 14.1406 14.7326C13.9668 14.8944 13.7077 14.8125 13.5999 14.7513C13.2031 14.5921 12.1272 14.1135 11.2696 13.2822C10.209 12.2541 10.1467 11.9003 9.94693 11.5855C9.78707 11.3336 9.90437 11.179 9.96291 11.1115C10.1914 10.8478 10.507 10.4407 10.6485 10.2384C10.79 10.0361 10.6776 9.72901 10.6102 9.53771C10.3203 8.715 10.0747 8.02631 9.87502 7.62516Z" fill="#1090CB"/>
              </svg>
              <span>8 727 310 02 58</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      accordion: 'collapse0',
      accordionData: [
        {
          'title': this.$t('faq-new.question-1'),
          'content': this.$t('faq-new.answer-1'),
          'content2': ''
        },
        {
          'title': this.$t('faq-new.question-2'),
          'content': this.$t('faq-new.answer-2'),
          'content2': ''
        },
        {
          'title': this.$t('faq-new.question-3'),
          'content': this.$t('faq-new.answer-3'),
          'content2': ''
        },
        {
          'title': this.$t('faq-new.question-4'),
          'content': this.$t('faq-new.answer-4'),
          'content2': ''
        },
        {
          'title': this.$t('faq-new.question-5'),
          'content': this.$t('faq-new.answer-5'),
          'content2': ''
        },
        {
          'title': this.$t('faq-new.question-6'),
          'content': this.$t('faq-new.answer-6'),
          'content2': ''
        },
        {
          'title': this.$t('faq-new.question-7'),
          'content': this.$t('faq-new.answer-7'),
          'content2': ''
        },
        {
          'title': this.$t('faq-new.question-8'),
          'content': this.$t('faq-new.answer-8'),
          'content2': ''
        },
        {
          'title': this.$t('faq-new.question-9'),
          'content': this.$t('faq-new.answer-9'),
          'content2': ''
        },
        {
          'title': this.$t('faq-new.question-10'),
          'content': this.$t('faq-new.answer-10'),
          'content2': ''
        },
      ],
    }
  },
  methods: {

  },
  mounted() {

  },
}
</script>

<style>

</style>